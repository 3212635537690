.footer {
  &--bar {
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 36px;
    display: flex;
  }

  &--el {
    width: 32px;
    height: 32px;
    font-size: 28px;
    margin: 0 auto;
    display: block;
  }
}
