@import "../variables";

.galleryModal {
  z-index: $gallery_modal_z_index;
  position: fixed;
  width: 100vw;
  top: $header_height;
  left: 0;

  &--layer {
    z-index: $gallery_modal_layer_z_index;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  &--target {
    width: 100vw;
    height: 60vh;
    object-fit: contain;
    display: block;

    &.out--topright {
      transform: translate(100vw, -50vh) rotate(45deg);
      transition: transform 1s ease 0.1s;
    }

    &.out--bottomleft {
      transform: translate(-100vw, 50vh) rotate(-45deg);
      transition: transform 1s ease 0.1s;
    }
  }

  &--controller {
    width: 100vw;
    position: fixed;
    bottom: 0;
    height: 84px;
    left: 0;
    display: flex;
    background: var(--theme-background);
    z-index: $gallery_modal_z_index;

    &__el {
      width: 33vw;
      text-align: center;
    }
  }
}
