$header_height: 64px;
$footer_height: 72px;

$font: "Noto Serif JP", serif;

// z-index layer
$header_z_index: 9;
$slide_front: 3;
$slide_left_right: 2;
$slide_back: -1;
$drawer_z_index: 8;
$drawer_z_index_out: -2;
$gallery_modal_layer_z_index: 6;
$gallery_modal_z_index: 7;
