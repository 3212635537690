@import "../variables";

.slide {
  height: 70vw;
  max-height: 450px;
  width: 90vw;
  max-width: 750px;
  margin: 12px auto;
  position: relative;

  .slideel {
    height: 60vw;
    max-height: 400px;
    width: 60vw;
    max-width: 400px;
    display: block;
    position: absolute;
    object-fit: contain;
    transition: all 0.5s ease-out;
  }

  .front {
    left: 10vw;
    z-index: $slide_front;
    opacity: 1;
  }

  .left {
    left: 0;
    z-index: $slide_left_right;
    opacity: 0.4;
  }

  .right {
    right: 0;
    z-index: $slide_left_right;
    opacity: 0.4;
  }
  .back {
    left: 10vw;
    z-index: $slide_back;
    opacity: 0;
  }
}
