:root {
  // --header-background-color: #f5f4eb;
  --header-background-color: transparent;

  // --theme-background: #fcfcf7;
  --theme-background: #fff;
  --theme-color: #0a0a00;
  --sub-theme-color: #f2aaf2;

  --drawer-background: #fff3a0;
}

@import "./_components/header";
@import "./_components/footer";
@import "./_components/drawer";
@import "./_components/footerbar";

.page {
  height: max-content;
  min-height: calc(100vh - #{$footer_height} - #{$header_height});
  position: relative;
  top: $header_height;
  color: var(--theme-color);
  background: var(--theme-background);
  overflow: hidden;
}

body {
  margin: 0;
  font-family: $font;
}

@import "./pages/gallery";
@import "./pages/main";
@import "./pages/profile";
