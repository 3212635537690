@import "../variables";

.profile {
  width: 80vw;
  max-width: 400px;
  margin: 12px auto;
  height: min-content;

  .img {
    img {
      height: 80vw;
      max-height: 400px;
      width: 100%;
      object-fit: contain;
      border: 1px solid var(--theme-color);
      border-radius: 999px;
    }
  }

  .profile {
    .name {
      font-size: 36px;
      text-align: center;
    }
  }

  .link {
    position: relative;
    height: 24px;
  }
}
