@import "../variables";

.drawer--wrapper {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  z-index: $drawer_z_index;

  transform-origin: top right;

  // transform: rotate(360deg);

  transition: transform 0.8s ease 0s;

  &.out {
    transform: rotate(180deg);
    // z-index: $drawer_z_index_out;
  }

  .drawer {
    width: 100vw;
    height: 100vh;
    position: fixed;

    background: var(--drawer-background);

    &--content {
      height: calc(100vh - #{$header_height});
      position: relative;
      top: $header_height;
    }

    &--list {
      list-style: none;
      font-size: 38px;
      &__anchor {
        text-decoration: none;
        color: var(--theme-color);
        margin: 4px 0;
      }
    }
  }
}
